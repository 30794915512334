/**
 * Start Page
 * 
 * Page where the users start the hunt and hunt timer (if enabled).
 * Team captain is the only one allowed to actually trigger the start,
 * the rest of the team gets a loading icon and waits for the captain to
 * start the game.
 * 
 * The toucan is a "hidden" button for the captain, so that the Host has to 
 * tell the teams to "tap the toucan to start the game". This is to try and prevent
 * teams from moving on and starting before everyone else is ready.
 */

import React from 'react'
import { navigate } from 'gatsby'
import Modal from 'react-bootstrap/Modal'

import { teamUpdateStart, getHuntStatus, isTeamCaptain, updateCurrentTeamData } from '../utils/team-data'
import { getSiteData } from '../utils/site'

import LoggedInWrapper from '../components/loggedinWrapper'
import { ToucanIllustration } from '../components/images/illustrations'
import AppContext from '../utils/context'
import SVGLoader from '../components/svg/loader'
import { getCurrentHuntData } from '../utils/hunt-data'

export default class StartPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      start_text: `Are you ready to have some fun!?`,
      modalStartShow: false,
    }
    this.interval = null
    this.startGame = this.startGame.bind(this)
  }

  componentDidMount() {
    getSiteData().then(data => {
      if (data.start_text !== null) {
        this.setState({
          start_text: data.start_text,
        })
      }
    })
    if (getHuntStatus() == `started`) {
      navigate(`/`)
    }
    if (!isTeamCaptain()) {
      this.interval = setInterval(() => this.checkIfStarted(), 5000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  checkIfStarted() {
    if (!isTeamCaptain()) {
      updateCurrentTeamData().then(() => {
        if (getHuntStatus() == `started`) {
          navigate(`/`)
        }
      })
    }
  }

  startGame() {
    this.setState({ modalStartShow: false }, () => {
      this.setLoading(true)
    })
    if (getHuntStatus() == `pending`) {
      teamUpdateStart()
    }
    navigate(`/`)
  }

  render() {
    const data = getCurrentHuntData()
    const isCaptain = isTeamCaptain()
    let modalStartClose = () => this.setState({ modalStartShow: false })

    return (
      <AppContext.Consumer>
        {({ setLoading }) => {
          this.setLoading = setLoading
          return (
            <LoggedInWrapper>
              <div className={`card full-height mx-4`}>
                <div className={`card-body`}>
                  {data !== {} && data.hunt !== undefined && (
                    <>
                      <div className={`mt-4 px-4`}>
                        <div className={`mt-3`}>
                          <h1 className="sr-only">Start Game</h1>
                          <h6 className={`text-center h5 title text-uppercase`}>Are You ready to have some fun?</h6>
                        </div>
                      </div>

                      {isCaptain ? (
                        <>
                          <button
                            className={`btn mt-5`}
                            onClick={() => {
                              this.setState({ modalStartShow: true })
                            }} aria-label="Start">
                            <ToucanIllustration />
                          </button>
                        </>
                      ) : (
                        <div className={`text-center`}>
                          <p className={`lead h6 px-3`}>Waiting for the team captain to start the game...</p>
                          <SVGLoader className={`text-primary`} width={20} height={20} />
                          <div className={`mt-3`}>
                            <ToucanIllustration />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <Modal show={this.state.modalStartShow} onHide={modalStartClose} centered aria-labelledby="start-modal-title">
                <Modal.Header closeButton>
                  <Modal.Title id="start-modal-title" className={`px-5`}>Are you sure you want to start the timer?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className={`mt-5`}>
                    <button className={`btn btn-block btn-success mb-3`} onClick={this.startGame}>
                      Yes!
                    </button>
                    <button className={`btn btn-block btn-tan`} onClick={modalStartClose}>
                      Cancel
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </LoggedInWrapper>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
