/**
 * Illustration Images Components
 */

import React from 'react'
import Image from '../image'

export class CrownIllustration extends React.Component {
  render() {
    return (
      <div
        style={{
          width: `102px`,
          height: `100px`,
          position: `absolute`,
          left: 14,
          top: 7,
          animation: ` wiggle 4s infinite`,
        }}>
        <Image alt="Crown Illustration" filename="crown.png" />
      </div>
    )
  }
}

export class GoggleDogIllustration extends React.Component {
  render() {
    return (
      <div
        style={{
          width: `133px`,
          height: `129px`,
          position: `absolute`,
          left: -5,
          top: -5,
          animation: ` wiggle 4s infinite`,
        }}>
        <Image alt="Goggle Dog Illustration" filename="goggle-dog.png" />
      </div>
    )
  }
}

export class SkullIllustration extends React.Component {
  render() {
    return (
      <div
        style={{
          width: `108px`,
          height: `129px`,
          position: `absolute`,
          left: 2,
          top: -10,
          animation: ` wiggle 4s infinite`,
        }}>
        <Image alt="Skull Illustration" filename="skull.png" />
      </div>
    )
  }
}

export class KeysIllustration extends React.Component {
  render() {
    return (
      <div
        style={{
          width: `120px`,
          height: `124px`,
          margin: `auto`,
        }}>
        <Image alt="Keys Illustration" filename="keys.png" />
      </div>
    )
  }
}

export class ToucanIllustration extends React.Component {
  render() {
    return (
      <div
        style={{
          width: `310px`,
          height: `366px`,
          margin: `auto`,
          maxWidth: `100%`,
        }}>
        <Image alt="Toucan Illustration" filename="toucan.png" />
      </div>
    )
  }
}

export class PlaneIllustration extends React.Component {
  render() {
    return (
      <div
        style={{
          width: `236px`,
          height: `164px`,
          margin: `auto`,
          maxWidth: `100%`,
          animation: ` wiggle 4s infinite`,
        }}>
        <Image alt="Plane Illustration" filename="plane.png" />
      </div>
    )
  }
}

export class CompassIllustration extends React.Component {
  render() {
    return (
      <div
        style={{
          width: `206px`,
          height: `180px`,
          margin: `auto`,
          maxWidth: `100%`,
          animation: ` wiggle 4s infinite`,
        }}>
        <Image alt="Compass Illustration" filename="compass.png" />
      </div>
    )
  }
}

export class DirigibleIllustration extends React.Component {
  render() {
    return (
      <div
        style={{
          width: `221px`,
          height: `64px`,
          margin: `auto`,
          maxWidth: `100%`,
        }}>
        <Image alt="Dirigible Illustration" filename="dirigible.png" />
      </div>
    )
  }
}
